export enum LAYOUT_MODES {
  LIGHT = "light",
  DARK = "dark",
}

export enum CHATS_TABS {
  ARCHIVE = "acrhive",
  DEFAULT = "default",
}

export enum TABS {
  USERS = "pills-user",
  CHAT = "pills-chat",
  CONTACTS = "pills-contacts",
  CALLS = "pills-calls",
  BOOKMARK = "pills-bookmark",
  SETTINGS = "pills-setting",
  PROFILE = "pills-profile",
  INTEGRATION = "pills-integration",
  COMPANIES = "pills-companies",
}
