import React, { useState, useEffect } from "react";
import { Button, Collapse } from "reactstrap";
import classnames from "classnames";
import { createSelector } from "reselect";
// hooks
import { useRedux } from "../../../hooks/index";

// actions
import { getSettings, updateSettings } from "../../../redux/actions";

// constants
import { SETTINGS_COLLAPSES } from "../../../constants";

// interface
import { SettingsTypes } from "../../../data/settings";

// components
import Loader from "../../../components/Loader";
import env from '../../../config'
import AppSimpleBar from "../../../components/AppSimpleBar";
import UserCoverImage from "./UserCoverImage";
import UserProfile from "./UserProfile";
import PersonalInfo from "./PersonalInfo";
import ThemeSettings from "./ThemeSettings";
import Privacy from "./Privacy";
import Security from "./Security";
import Help from "./Help";
import Address from "./Address";
import SocialLinks from "./SocialLinks";
import { useAgencyInfo } from "../../../context/agency";
import { useAppServices } from "../../../hook/services";

interface CollapseItemTypes {
  value:
  | SETTINGS_COLLAPSES.PROFILE
  | SETTINGS_COLLAPSES.ADDRESS
  | SETTINGS_COLLAPSES.SOCAILLINKS
  | SETTINGS_COLLAPSES.HELP
  | SETTINGS_COLLAPSES.PRIVACY
  | SETTINGS_COLLAPSES.SECURITY
  | SETTINGS_COLLAPSES.THEME
  label: string;
  icon: string;
  component: any;
}

interface AccordianItemProps {
  item: CollapseItemTypes;
  onChange: (
    id:
      | null
      | SETTINGS_COLLAPSES.PROFILE
      | SETTINGS_COLLAPSES.ADDRESS
      | SETTINGS_COLLAPSES.SOCAILLINKS
      | SETTINGS_COLLAPSES.HELP
      | SETTINGS_COLLAPSES.PRIVACY
      | SETTINGS_COLLAPSES.SECURITY
      | SETTINGS_COLLAPSES.THEME
  ) => void;
  selectedMenu:
  | null
  | SETTINGS_COLLAPSES.PROFILE
  | SETTINGS_COLLAPSES.ADDRESS
  | SETTINGS_COLLAPSES.SOCAILLINKS
  | SETTINGS_COLLAPSES.HELP
  | SETTINGS_COLLAPSES.PRIVACY
  | SETTINGS_COLLAPSES.SECURITY
  | SETTINGS_COLLAPSES.THEME
}
const AccordianItem = ({
  item,
  selectedMenu,
  onChange,
}: AccordianItemProps) => {
  const isOpen: boolean =
    selectedMenu && selectedMenu === item.value ? true : false;
  const toggleCollapse = () => {
    if (isOpen) {
      onChange(null);
    } else {
      onChange(item.value);
    }
  };
  return (
    <div className="accordion-item">
      <div className="accordion-header" id="headerpersonalinfo">
        <Button
          color="none"
          className={classnames(
            "accordion-button",
            "font-size-14",
            "fw-medium",
            { collapsed: !isOpen }
          )}
          onClick={toggleCollapse}
          type="button"
        >
          <i className={classnames("text-muted", "me-3", item.icon)}></i>{" "}
          {item.label}
        </Button>
      </div>
      <Collapse
        isOpen={isOpen}
        id="personalinfo"
        className="accordion-collapse"
      >
        {item.component}
      </Collapse>
    </div>
  );
};
interface IndexProps { }
const Index = (props: IndexProps) => {
  // global store
  const [agency] = useAgencyInfo()
  const Service = useAppServices()
  const { dispatch, useAppSelector } = useRedux();


  const errorData = createSelector(
    (state: any) => state.Settings,
    (state: any) => state.Profile,
    (state) => ({
      settingsData: state.settings,
      getSettingsLoading: state.getSettingsLoading,
      isSettingsFetched: state.isSettingsFetched,
    })
  );
  // Inside your component
  const { settingsData, getSettingsLoading } = useAppSelector(errorData);

  // get user settings
  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  const [settings, setSettings] = useState<SettingsTypes>(settingsData);
  useEffect(() => {
    setSettings(settingsData);
  }, [settingsData]);

  /*
  api calling
  */
  const onChangeData = (field: string, value: any) => {
    dispatch(updateSettings(field, value));
  };

  /*
  collapse handeling
  */
  const [selectedMenu, setSelectedMenu] = useState<
    | null
    | SETTINGS_COLLAPSES.PROFILE
    | SETTINGS_COLLAPSES.ADDRESS
    | SETTINGS_COLLAPSES.SOCAILLINKS
    | SETTINGS_COLLAPSES.HELP
    | SETTINGS_COLLAPSES.PRIVACY
    | SETTINGS_COLLAPSES.SECURITY
    | SETTINGS_COLLAPSES.THEME
  >(null);

  const collapseItems: CollapseItemTypes[] = [
    {
      value: SETTINGS_COLLAPSES.PROFILE,
      label: "Personal Info",
      icon: "bx bxs-user",
      component: <PersonalInfo />,
    },
    {
      value: SETTINGS_COLLAPSES.ADDRESS,
      label: "Address",
      icon: "bx bxs-adjust-location",
      component: (
        <Address />
      ),
    },
    {
      value: SETTINGS_COLLAPSES.SOCAILLINKS,
      label: "Social Links",
      icon: "bx bxs-adjust-location",
      component: (
        <SocialLinks />
      ),
    },
    // {
    //   value: SETTINGS_COLLAPSES.THEME,
    //   label: "Themes",
    //   icon: "bx bxs-adjust-alt",
    //   component: (
    //     <ThemeSettings theme={settings.theme} onChangeData={onChangeData} />
    //   ),
    // },
    // {
    //   value: SETTINGS_COLLAPSES.PRIVACY,
    //   label: "Privacy",
    //   icon: "bx bxs-lock",
    //   component: (
    //     <Privacy privacy={settings.privacy} onChangeSettings={onChangeData} />
    //   ),
    // },
    // {
    //   value: SETTINGS_COLLAPSES.SECURITY,
    //   label: "Security",
    //   icon: "bx bxs-check-shield",
    //   component: (
    //     <Security
    //       security={settings.security}
    //       onChangeSettings={onChangeData}
    //     />
    //   ),
    // },
    // {
    //   value: SETTINGS_COLLAPSES.HELP,
    //   label: "Help",
    //   icon: "bx bxs-help-circle",
    //   component: <Help />,
    // },
  ];

  const onChangeCollapse = (
    id:
      null
      | SETTINGS_COLLAPSES.PROFILE
      | SETTINGS_COLLAPSES.ADDRESS
      | SETTINGS_COLLAPSES.SOCAILLINKS
      | SETTINGS_COLLAPSES.HELP
      | SETTINGS_COLLAPSES.PRIVACY
      | SETTINGS_COLLAPSES.SECURITY
      | SETTINGS_COLLAPSES.THEME
  ) => {
    setSelectedMenu(id);
  };
  // const auth_url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.LOCAITON}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.SCOPE}&state=${agency?.app_token?._id}`
  // const hanldeConnect = () => {
  //   var windowFeatures = 'width=600,height=600,menubar=no,location=no,toolbar=no,scrollbars=yes'

  //   // Open the URL in a new window with the specified features
  //   window.open(auth_url, '_blank', windowFeatures)
  // }
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const socail_links = {
      facebook: e.target.facebook.value,
      linkedin: e.target.linkedin.value,
      instagram: e.target.instagram.value,
    }
    const personalinfo = {
      name: e.target.name.value,
      email: e.target.email.value,
      website: e.target.website.value,
      phone: e.target.phone.value,
    }
    const addressinfo = {
      address: e.target.address.value,
      city: e.target.city.value,
      country: e.target.country.value,
      state: e.target.state.value,
    }
    const profile = {
      socail_links: socail_links,
      personalinfo: personalinfo,
      addressinfo: addressinfo,
    }
    const payload = {
      profile: profile,
      _id: agency?._id
    }
    console.log(payload, 'payload')
    const { response } = await Service.agency.update({ payload })
    if (response) {
    }
  }

  return (
    <div className="position-relative">
      {getSettingsLoading && <Loader />}
      <UserCoverImage basicDetails={settings.basicDetails} />

      <UserProfile
        basicDetails={settings.basicDetails}
        status={settings.status}
      />
      {/* Start User profile description */}
      <form onSubmit={handleSubmit}>
        <AppSimpleBar className="user-setting">
          <div id="settingprofile" className="accordion accordion-flush">
            {(collapseItems || []).map((item: CollapseItemTypes, key: number) => (
              <AccordianItem
                item={item}
                key={key}
                selectedMenu={selectedMenu}
                onChange={onChangeCollapse}
              />
            ))}
          </div>
          {/* <div className="d-flex mx-3 mt-2">
          <div
            className={classnames(
              "accordion-button",
              "font-size-14",
              "fw-medium",
              )}>
            Location Connect
          </div>
          <Button type="button" color="success" className="btn btn-sm" onClick={hanldeConnect}>
            Connect
          </Button>
        </div> */}
          {/* end profile-setting-accordion */}
          <div className="mt-3 mx-3 d-flex justify-content-end">

            <Button type="submit" color="success">
              Save
            </Button>
          </div>
        </AppSimpleBar>
      </form>
    </div>
  );
};

export default Index;
