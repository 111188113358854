import React, { useEffect, useState } from "react";
import {
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";

// interface
import { BasicDetailsTypes } from "../../../data/settings";

// CONSTANTS
import { STATUS_TYPES } from "../../../constants";
import env from "../../../config";
import { useAgencyInfo } from "../../../context/agency";
import { useAppServices } from "../../../hook/services";
import axios from "axios";

interface UserProfileProps {
  basicDetails: BasicDetailsTypes;
  status: STATUS_TYPES;
}
const UserProfile = ({ basicDetails, status }: UserProfileProps) => {
  const BASE_URL = `${env.API_URL}/api/v1`;
  const [agency] = useAgencyInfo()
  const Service = useAppServices()
  const fullName = basicDetails
    ? `${basicDetails.firstName} ${basicDetails.lastName}`
    : "-";

  /*
    profile image
    */
  const [image, setImage] = useState<string>('');
  useEffect(() => {
    if (agency?.profile?.personalinfo && agency?.profile?.personalinfo?.profile_image) {
      setImage(agency?.profile?.personalinfo?.profile_image);
    }
  }, [agency?.profile?.personalinfo]);
  const onChangeProfile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target;

    // Check if a file was selected
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const formData = new FormData();

      formData.append('image', file);

      // Make a POST request to the server
      const image_data = await axios.post(
        BASE_URL + '/utils/upload/image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
        .then((response) => {
          // Handle the response from the server
          console.log(response)
          return response.data.data;
        })
        .catch((error) => {
          console.error('Error uploading image:', error);
          // You can handle errors here
        });
      setImage(image_data)
      const profile = {
        ...agency?.profile, personalinfo: {
          ...agency?.profile?.personalinfo,
          profile_image: image_data
        },
      }
      const payload = {
        profile: profile,
        _id: agency?.app_token?._id
      }
      console.log(payload, 'payload')
      const result = await Service.agency.update({ payload })
      if (result) {
      }
    }
  };

  /*
    drop down state
    */
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  /*
    status
    */
  const [userStatus, setUserStatus] = useState<STATUS_TYPES>(
    STATUS_TYPES.ACTIVE
  );
  const onChangeStatus = (status: STATUS_TYPES) => {
    setUserStatus(status);
  };
  useEffect(() => {
    setUserStatus(status);
  }, [status]);

  return (
    <div className="text-center p-3 p-lg-4 border-bottom pt-2 pt-lg-2 mt-n5 position-relative">
      <div className="mb-3 profile-user">
        <img
          src={image}
          className="rounded-circle avatar-lg img-thumbnail user-profile-image"
          alt="company-logo"
        />
        <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
          <input
            onChange={(e: any) => onChangeProfile(e)}
            id="profile-img-file-input"
            type="file"
            className="profile-img-file-input"
            accept="image/png, image/jpeg"
          />
          <Label
            htmlFor="profile-img-file-input"
            className="profile-photo-edit avatar-xs"
          >
            <span className="avatar-title rounded-circle bg-light text-body">
              <i className="bx bxs-camera"></i>
            </span>
          </Label>
        </div>
      </div>

      {/* <h5 className="font-size-16 mb-1 text-truncate">{fullName}</h5> */}

      {/* <Dropdown
        className="d-inline-block"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle tag="a" className="text-muted d-block" role="button">
          <i
            className={classnames(
              "bx",
              "bxs-circle",
              "font-size-10",
              "align-middle",
              { "text-success": userStatus === STATUS_TYPES.ACTIVE },
              { "text-warning": userStatus === STATUS_TYPES.AWAY },
              { "text-danger": userStatus === STATUS_TYPES.DO_NOT_DISTURB }
            )}
          ></i>{" "}
          {userStatus} <i className="mdi mdi-chevron-down"></i>
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem onClick={() => onChangeStatus(STATUS_TYPES.ACTIVE)}>
            <i className="bx bxs-circle text-success font-size-10 me-1 align-middle"></i>{" "}
            Active
          </DropdownItem>
          <DropdownItem onClick={() => onChangeStatus(STATUS_TYPES.AWAY)}>
            <i className="bx bxs-circle text-warning font-size-10 me-1 align-middle"></i>{" "}
            Away
          </DropdownItem>
          <DropdownItem
            onClick={() => onChangeStatus(STATUS_TYPES.DO_NOT_DISTURB)}
          >
            <i className="bx bxs-circle text-danger font-size-10 me-1 align-middle"></i>{" "}
            Do not disturb
          </DropdownItem>
        </DropdownMenu>
      </Dropdown> */}
    </div>
  );
};

export default UserProfile;
