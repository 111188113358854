import React, { useEffect, useState } from "react";
// components
import Loader from "../../../components/Loader";
import AppSimpleBar from "../../../components/AppSimpleBar";
import InviteContactModal from "../../../components/InviteContactModal";
import EmptyStateResult from "../../../components/EmptyStateResult";
import ListHeader from "./ListHeader";
import Company from "./Company";
import { useContactsInfo } from "../../../context/contact";
import { CompanyTypes, ContactTypes } from "../../../data";

interface IndexProps { }

const Index = (props: IndexProps) => {
    const { locations, setactive_conversation_user } = useContactsInfo()
    const [companieslist, setcompanieslist] = useState<Array<any>>(locations);

    useEffect(() => {
        if (locations.length > 0) {
            setcompanieslist(locations);
        }
    }, [locations]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const openModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };

    /*
    onInvite handeling
    */
    const onInviteContact = (data: any) => {
        // dispatch(inviteContact(data));
    };
    const [search, setSearch] = useState("");
    const onChangeSearch = (value: string) => {
        setSearch(value);
        let modifiedCompanies = [...locations];
        let filteredCompanies = (modifiedCompanies || []).filter((c: any) =>
            c["name"].toLowerCase().includes(value.toLowerCase())
        );
        setcompanieslist(filteredCompanies);
    };

    const totalC = (companieslist || []).length;
    const onSelectChat = (company: object | number, isChannel?: boolean) => {
        setactive_conversation_user(company)
    };

    return (
        <>
            <div className="position-relative">
                <ListHeader
                    search={search}
                    onChangeSearch={onChangeSearch}
                    openModal={openModal}
                />

                <AppSimpleBar className="chat-message-list chat-group-list">
                    <div>
                        {totalC === 0 ? (
                            <EmptyStateResult searchedText={search} />
                        ) : (
                            (companieslist || []).map(
                                (letterCompanies: CompanyTypes, key: number) => (
                                    <Company
                                        letterCompanies={letterCompanies}
                                        key={key}
                                        index={key}
                                        onSelectChat={onSelectChat}
                                    />
                                )
                            )
                        )}
                    </div>
                </AppSimpleBar>
            </div>
            <InviteContactModal
                isOpen={isOpen}
                onClose={closeModal}
                onInvite={onInviteContact}
            />
        </>
    );
};

export default Index;
