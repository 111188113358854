import React, { useEffect, useState } from "react";

// hooks
import { useRedux } from "../../../hooks/index";
import { createSelector } from "reselect";
// actions
import {
  toggleUserDetailsTab,
  getChatUserConversations,
  onSendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  deleteUserMessages,
  toggleArchiveContact,
} from "../../../redux/actions";

// hooks
import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";

// interface
import { MessagesTypes } from "../../../data/messages";

// dummy data
import { pinnedTabs } from "../../../data/index";
import { useSocket } from "../../../context/socket";
import { useAgencyInfo } from "../../../context/agency";
import { useContactsInfo } from "../../../context/contact";

interface IndexProps {
  isChannel: boolean;
}
const Index = ({ isChannel }: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const socket = useSocket();

  const errorData = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      chatUserDetails: state.chatUserDetails,
      chatUserConversations: state.chatUserConversations,
      isUserMessageSent: state.isUserMessageSent,
      isMessageDeleted: state.isMessageDeleted,
      isMessageForwarded: state.isMessageForwarded,
      isUserMessagesDeleted: state.isUserMessagesDeleted,
      isImageDeleted: state.isImageDeleted,
    })
  );
  // Inside your component
  const { chatUserDetails, chatUserConversations, isUserMessageSent, isMessageDeleted, isMessageForwarded, isUserMessagesDeleted,
    isImageDeleted } = useAppSelector(errorData);
  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };

  /*
  hooks
  */
  const { userProfile } = useProfile();
  const { active_conversation_user, getConversation, active_conversation } = useContactsInfo()

  const [agency] = useAgencyInfo()
  var user_info = active_conversation_user
  /*
  reply handeling
  */
  const [UserConversation, setUserConversation] = useState<any>(chatUserConversations)
  const [test, settest] = useState<any>('testing')

  const [replyData, setReplyData] = useState<
    null | MessagesTypes | undefined
  >();
  const onSetReplyData = (reply: null | MessagesTypes | undefined) => {
    setReplyData(reply);
  };

  /*
  send message
  */
  const onSend = (data: any) => {
    const isCustom = active_conversation_user?.isCustom == true ? true : false
    let params: any = {
      text: data.text && data.text,
      type: 'agency',
      agency_id: agency._id,
      chat_id: active_conversation._id,
      isCustom: isCustom,
      Conversation_id: active_conversation_user._id,
      email: active_conversation_user.type == 'user' ? active_conversation_user.email : null,
      location_id: active_conversation_user.type == 'location_chat' ? active_conversation_user.id : null,
      time: new Date().toISOString(),
      image: data.image && data.image,
      newimage: data.newimage && data.newimage,
      attachments: data.attachments && data.attachments,
      meta: {
        receiver: active_conversation_user.id,
        sender: agency._id,
      },
    };
    socket?.emit("conversation", params);
    if (UserConversation?.messages?.length) {

      setUserConversation({ ...UserConversation, messages: [...UserConversation?.messages, params] })
    } else {
      setUserConversation({ ...UserConversation, messages: [params] })
    }
    console.log(params, 'params')

    if (replyData && replyData !== null) {
      params["replyOf"] = replyData;
    }

    // dispatch(onSendMessage(params));
    // if (!isChannel) {
    //   setTimeout(() => {
    //     dispatch(receiveMessage(chatUserDetails.id));
    //   }, 1000);
    //   setTimeout(() => {
    //     dispatch(readMessage(chatUserDetails.id));
    //   }, 1500);
    //   setTimeout(() => {
    //     dispatch(receiveMessageFromUser(chatUserDetails.id));
    //   }, 2000);
    // }
    setReplyData(null);
  };

  useEffect(() => {
    socket?.on("conversationResponse", async (data: any) => {
      // console.log(data, 'conversationResponse')
      // console.log(active_conversation_user, 'active_conversation_user')
      console.log(data, 'data')
      console.log(active_conversation, 'active_conversation')
      if (data._id) {
        if (active_conversation_user?.isCustom) {
          getConversation(`isCustom=${true}&_id=${active_conversation_user._id}`)
        } else if (active_conversation_user?.type == "user") {
          getConversation(`agency_id=${agency._id}&email=${active_conversation_user.email}`)

        } else {
          getConversation(`agency_id=${agency._id}&location_id=${active_conversation_user.id}`)
        }
      } else {

        if (data.type === 'location' && data.chat_id === active_conversation?._id) {

          setUserConversation((prevUserConversation: { messages: any; }) => {
            return {
              ...prevUserConversation,
              messages: [...prevUserConversation?.messages, data],
            };
          });
        }
      }
    });
    return () => {
      socket && socket.off("conversationResponse");
    };
  }, [socket, active_conversation,]);
  useEffect(() => {
    console.log(UserConversation, 'UserConversation')

  }, [UserConversation]);
  useEffect(() => {
    if (active_conversation_user?.isCustom) {
      getConversation(`isCustom=${true}&_id=${active_conversation_user._id}`)
    } else if (active_conversation_user?.type == "user") {
      getConversation(`agency_id=${agency._id}&email=${active_conversation_user.email}`)

    } else {
      getConversation(`agency_id=${agency._id}&location_id=${active_conversation_user.id}`)
    }
  }, [active_conversation_user]);
  useEffect(() => {
    setUserConversation(active_conversation)
    console.log(UserConversation, 'UserConversation')
  }, [
    active_conversation
  ]);

  const onDeleteMessage = (messageId: string | number) => {
    dispatch(deleteMessage(chatUserDetails.id, messageId));
  };

  const onDeleteUserMessages = () => {
    dispatch(deleteUserMessages(chatUserDetails.id));
  };

  const onToggleArchive = () => {
    dispatch(toggleArchiveContact(chatUserDetails.id));
  };

  return (
    <>
      <UserHead
        chatUserDetails={active_conversation_user}
        pinnedTabs={pinnedTabs}
        onOpenUserDetails={onOpenUserDetails}
        onDelete={onDeleteUserMessages}
        isChannel={false}
        onToggleArchive={onToggleArchive}
      />
      <Conversation
        chatUserConversations={UserConversation}
        chatUserDetails={active_conversation_user}
        onDelete={onDeleteMessage}
        onSetReplyData={onSetReplyData}
        isChannel={false}
      />
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={active_conversation_user}
      />
    </>
  );
};

export default Index;
