import React, { useState } from "react";
import { useAgencyInfo } from "../../../context/agency";
const Address = () => {
  const [agency] = useAgencyInfo()
  return (
    <div className="accordion-body">

      <div>
        <p className="text-muted mb-1" style={{ fontSize: '13px' }}>Address</p>
        <input type="text" className="form-control" defaultValue={agency?.profile?.addressinfo?.address} name="address" />
      </div>
      <div className="mt-3">
        <p className="text-muted mb-1" style={{ fontSize: '13px' }}>City</p>
        <input type="text" className="form-control" defaultValue={agency?.profile?.addressinfo?.city} name="city" />
      </div>
      <div className="mt-3">
        <p className="text-muted mb-1" style={{ fontSize: '13px' }}>Country</p>
        <input type="text" className="form-control" defaultValue={agency?.profile?.addressinfo?.country} name="country" />
      </div>
      <div className="mt-3">
        <p className="text-muted mb-1" style={{ fontSize: '13px' }}>State / Prov / Region *</p>
        <input type="text" className="form-control" defaultValue={agency?.profile?.addressinfo?.state} name="state" />
      </div>
    </div>
  );
};

export default Address;
