import React, { useEffect, useState } from "react";
import { Label, UncontrolledTooltip } from "reactstrap";

// interface
import { BasicDetailsTypes } from "../../../data/settings";
import { useAgencyInfo } from "../../../context/agency";
import { useAppServices, useUploadImage } from "../../../hook/services";
import axios from "axios";
import env from "../../../config";

interface UserCoverImageProps {
  basicDetails: BasicDetailsTypes;
}
const UserCoverImage = ({ basicDetails }: UserCoverImageProps) => {
  const BASE_URL = `${env.API_URL}/api/v1`;

  const [agency] = useAgencyInfo()
  const uploadImage = useUploadImage()
  const Service = useAppServices()
  const [image, setImage] = useState<string>('');
  useEffect(() => {
    if (agency?.profile?.personalinfo && agency?.profile?.personalinfo?.cover_image) {
      setImage(agency?.profile?.personalinfo?.cover_image);
    }
  }, []);
  const onChangeProfileCover = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target;

    // Check if a file was selected
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const formData = new FormData();

      formData.append('image', file);

      // Make a POST request to the server
      const image_data = await axios.post(
        BASE_URL + '/utils/upload/image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
        .then((response) => {
          // Handle the response from the server
          console.log(response)
          return response.data.data;
        })
        .catch((error) => {
          console.error('Error uploading image:', error);
          // You can handle errors here
        });
      setImage(image_data)
      const profile = {
        ...agency?.profile, personalinfo: {
          ...agency?.profile?.personalinfo,
          cover_image: image_data
        },
      }
      const payload = {
        profile: profile,
        _id: agency?._id
      }
      console.log(payload, 'payload')
      const result = await Service.agency.update({ payload })
      if (result) {
      }
    }
  };
  return (
    <div className="user-profile-img">
      <img
        src={image}
        className="profile-img profile-foreground-img"
        style={{ height: "160px" }}
        alt=""
      />
      <div className="overlay-content">
        <div>
          <div className="user-chat-nav p-3">
            <div className="d-flex w-100 align-items-center">
              <div className="flex-grow-1">
                <h5 className="text-white mb-0">Company Cover Image</h5>
              </div>
              <div className="flex-shrink-0">
                <div
                  className="avatar-xs p-0 rounded-circle profile-photo-edit"
                  id="change-cover"
                >
                  <input
                    id="profile-foreground-img-file-input"
                    type="file"
                    accept="image/png, image/jpeg"
                    className="profile-foreground-img-file-input"
                    onChange={e => onChangeProfileCover(e)}
                  />
                  <Label
                    htmlFor="profile-foreground-img-file-input"
                    className="profile-photo-edit avatar-xs"
                  >
                    <span className="avatar-title rounded-circle bg-light text-body">
                      <i className="bx bxs-pencil"></i>
                    </span>
                  </Label>
                </div>
                <UncontrolledTooltip target="change-cover" placement="bottom">
                  Change Background
                </UncontrolledTooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCoverImage;
