/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useState, useMemo, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// import localStorage from "localStorage";
import { useAppServices } from "../hook/services";
// import Loader from "examples/Loader";
import { useUserInfo } from "./user";
import { TABS } from "../constants";
// import { useNavigate } from "react-router-dom";
// import env from "../config";
// import {
//   setDarkMode,
//   setFixedNavbar,
//   setSidenavColor,
//   setTransparentSidenav,
//   setWhiteSidenav,
//   useMaterialUIController,
// } from "context";

// Material Dashboard 2 React main context
const ControllerContext = createContext();

// Setting custom name for the context which is visible on react dev tools
ControllerContext.displayName = "ControllerContext";

// Material Dashboard 2 React context provider
function ControllerProvider({ children }) {
  // const Service = useAppServices();
  // const [user] = useUserInfo();
  const [ActiveTab, setActiveTab] = useState(TABS.CHAT);
  const [loader, setLoader] = useState(false);
  // alert(ActiveTab)
  // alert(ActiveTab)
  const handleTabs = () => {
    onLoad()
  }
  // const clear = () => setController({});

  const value = useMemo(() => [ActiveTab, setActiveTab], [ActiveTab, setActiveTab]);

  const onLoad = () => {
    // updateController();
  };

  useEffect(onLoad, []);

  return loader ? (
    <></>
  ) : (
    <ControllerContext.Provider value={value}>{children}</ControllerContext.Provider>
  );
}

// Material Dashboard 2 React custom hook for using context
function useControllerInfo() {
  return useContext(ControllerContext) || [];
}

// Typechecking props for the MaterialUIControllerProvider
ControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ControllerProvider, useControllerInfo };
