import { useEffect, useState } from "react";
import { useAppServices } from "../../../hook/services";
import { useAgencyInfo } from "../../../context/agency";
import { INTEGRATION_Data } from "./Constant";

const Data = () => {
    const AppService = useAppServices()
    const [agency] = useAgencyInfo()
    const [data, setData] = useState<INTEGRATION_Data>({
        worflows: [],
        custom_fields: [],
    })
    const [loader, setloader] = useState(true)
    const getIntegrationData = async () => {
        const { response } = await AppService.agency.getworkflows({
            query: `_id=${agency?._id}`
        });
        console.log(response, 'responseresponse')
        if (response) {
            const { workflows, data } = response
            setData({
                custom_fields: data,
                worflows: workflows
            })
            setloader(false)
        } else {
            setloader(false)
        }
    }

    const onLoad = () => {
        getIntegrationData()
    }
    const handleRefresh = () => {
        onLoad()
    }
    useEffect(() => {
        onLoad()
    }, []);

    return {
        data: data,
        loader: loader,
        handleRefresh: handleRefresh,
    };
};
export default Data;
