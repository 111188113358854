import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import QueryString from "qs";
import { ReqMethods, ghl } from "../../../enums";
import env from "../../../config";
import { useAppServices } from "../../../hook/services";
import { Button } from "reactstrap";
import { useAgencyInfo } from "../../../context/agency";
// import { AgencyContext } from '../../../context/Agency.context'

function GHL() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const code = new URLSearchParams(search).get("code");
  const type = new URLSearchParams(search).get("type");
  const state = new URLSearchParams(search).get("state");
  const AppServices = useAppServices();
  const [Error, setError] = useState("");
  const [agency, update] = useAgencyInfo();
  console.log(agency)
  const associate = async ({ location }, creds) => {
    const payload = {
      ghl: {
        location_id: creds.locationId,
        company_id: location.companyId,
        access_token: creds.access_token,
        refresh_token: creds.refresh_token,
      },
      _id: state
    }
    console.log(payload, 'payload')
    const { response } = await AppServices.agency.update({ payload })
    if (response) {
    }

    if (!response) return setError("Something went wrong while integration, please try latter");
    // update(payload);
    return setError("Location Connected Successfully");
  };

  const getLocation = async (creds) => {
    const payload = {
      method: ReqMethods.GET,
      path: `${ghl.APIs.v2.location(creds.locationId)}`,
      key: creds.access_token,
      refresh_token: creds.refresh_token,
    };
    // console.log(payload)
    const { response } = await AppServices.services.ghl.call_service({ payload });

    if (!response) return setError("Something went wrong while integration, please try latter");
    // console.log(response.data)

    return response && associate(response.data, creds) && setError("Location SuccessFully connected");
  };

  const getCreds = async () => {
    const payload = {
      method: ReqMethods.POST,
      path: ghl.APIs.oauth,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      payload: QueryString.stringify({
        client_id: env.GHL.CLIENT_ID,
        client_secret: env.GHL.CLIENT_SECRET,
        grant_type: "authorization_code",
        code,
        refresh_token: "",
      }),
    };
    // console.log(payload)
    const { response } = await AppServices.services.ghl.call_service({ payload });

    if (!response) return setError("Something went wrong while integration, please try latter");
    // if(location_id !== response.data.locationId) return setError('location ID mismatch')
    // console.log(response.data)
    return response && getLocation(response.data);
  };

  const onLoad = () => {
    // alert('test')
    getCreds();
  };

  useEffect(onLoad, []);

  return (
    <div>
      {!Error && <div>Integrating please wait...</div>}
      {Error && (
        <div>
          {Error}
          <Button onClick={() => navigate(-2)}>Go Back</Button>
        </div>
      )}
    </div>
  );
}

export default GHL;
