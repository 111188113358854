import axios from "axios";
import { ReqMethods, ResponseStatus } from "../enums";
import env from "../config";
// import { setNotification, useNotifcation } from "context/notification";
import { useAuth, useLogout } from "./auth";

const BASE_URL = `${env.API_URL}/api/agency_app`;

function useServiceHandler() {
  // const [, dispatch] = useNotifcation();
  const Logout = useLogout();

  return (fn) =>
    async (method, path, { query, payload, token, apiKey, toaster, message, error } = {}) => {
      try {
        const res = await fn(method, path, { query, payload, token, apiKey });
        console.log("API - RESPONSE", res, toaster, message, error);
        // toaster
        // setNotification(dispatch, {
        //   open: true,
        //   message: message || res.data.message,
        //   severity: "success",
        //   title: "Success",
        // });

        return { response: res.data };
      } catch (err) {
        console.log("API- ERROR", err.response?.data || err);

        // expire error : jwt expired
        if (err.response && err.response.status === ResponseStatus.UNAUTHORIZED) {
          // setNotification(dispatch, {
          //   open: true,
          //   message: error || "session expired",
          //   title: "Error",
          //   severity: "error",
          // });
          // setTimeout(Logout, 4000);
          return { error: err.response?.data || err };
        }

        const customError = err.response?.data?.error
          ? `${err.response?.data?.message} \n${err.response?.data?.error}`
          : err.response?.data?.message;


        return { error: err.response ? err.response.data : err };
      }
    };
}

function useCallService() {
  const authToken = useAuth();
  const serviceHandler = useServiceHandler();

  const CallService = (method, path, { query, payload, token = true, apiKey = null }) => {
    const pathname = query ? `${path}?${query}` : path;
    const config = {};

    if (token) config.headers = { "x-auth-token": `Bearer ${authToken || token}` };
    if (apiKey) config.headers = { apiKey };

    const details = {};

    if (payload) details.payload = payload;
    details.config = config;

    return axios[method](pathname, ...Object.values(details));
  };

  return serviceHandler(CallService);
}

function useAppServices() {
  const { GET, POST, PUT, DELETE } = ReqMethods;
  const CallService = useCallService();

  /**
    Option for service is the object that could has the following properties
    query, payload, token, apiKey
  */

  const APIs = {
    auth: {
      userlogin: (options) => CallService(GET, `${BASE_URL}/auth/userlogin`, options),
    },
    chat: {
      getContacts: (options) => CallService(GET, `${BASE_URL}/chat/get-contacts`, options),
      getConversation: (options) => CallService(GET, `${BASE_URL}/chat/get-conversation`, options),
      getCustomConversation: (options) => CallService(GET, `${BASE_URL}/chat/get-Custom-conversation`, options),
      createCustomConversation: (options) => CallService(POST, `${BASE_URL}/chat/create-custom-conversation`, options),
    },
    agency: {
      get: (options) => CallService(GET, `${BASE_URL}/agency`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/agency`, options),
      getworkflows: (options) => CallService(GET, `${BASE_URL}/agency/getworkflows`, options),
      profile: (options) => CallService(POST, `${BASE_URL}/agency/profile`, options),
      prospect: (options) => CallService(GET, `${BASE_URL}/agency/getprospect`, options),
      get_prospective: (options) => CallService(GET, `${BASE_URL}/agency/getprospective`, options),
    },
    assign_user: {
      get: (options) => CallService(GET, `${BASE_URL}/assign_user`, options),
      filter: (options) => CallService(GET, `${BASE_URL}/assign_user/filter`, options),
      update: (options) => CallService(PUT, `${BASE_URL}/assign_user`, options),
      create: (options) => CallService(POST, `${BASE_URL}/assign_user`, options),
    },
    utils: {
      upload_image: (options) => CallService(POST, `${BASE_URL}/utils/upload/image`, options),
    },
    services: {
      ghl: {
        call_service: (options) => CallService(POST, `${BASE_URL}/services/ghl/`, options),
        UploadFile: (options) => CallService(POST, `${BASE_URL}/services/ghl/file_upload`, options),
      },
    },
  };

  return APIs;
}

const useUploadImage = () => {
  const AppService = useAppServices();
  return ({ toaster, file, desiredPath }) => {
    const form = new FormData();
    form.append("image", file, file.name);
    console.log(file, 'file')
    console.log(form.get("image"), 'form')

    return AppService.utils.upload_image({
      toaster,
      payload: form.get("image"),
      query: `desiredPath=${desiredPath}`,
    });
  };
};

export { useAppServices, useCallService, useUploadImage };
