/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useState, useMemo, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// import localStorage from "localStorage";
import { useAppServices } from "../hook/services";
// import Loader from "examples/Loader";
import { useUserInfo } from "./user";
import { useAgencyInfo } from "./agency";
import { useSocket } from "./socket";
// import { useNavigate } from "react-router-dom";
// import env from "../config";
// import {
//   setDarkMode,
//   setFixedNavbar,
//   setSidenavColor,
//   setTransparentSidenav,
//   setWhiteSidenav,
//   useMaterialUIController,
// } from "context";

// Material Dashboard 2 React main context
const contactContext = createContext();

// Setting custom name for the context which is visible on react dev tools
contactContext.displayName = "contactContext";

// Material Dashboard 2 React context provider
function ContactsProvider({ children }) {
  const Service = useAppServices();
  const socket = useSocket();
  const [agency] = useAgencyInfo()
  const [contacts, setContacts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [allchats, setAllchats] = useState({
    direct_chats: [],
    custom_chats: [],
  });
  const [active_conversation, setactive_conversation] = useState({});
  const [conversation_loader, setconversation_loader] = useState(false);
  const [active_conversation_user, setactive_conversation_user] = useState(null);
  const [loader, setLoader] = useState(true);

  const getConversation = async (query) => {
    setconversation_loader(true)
    const { response } = await Service.chat.getConversation({
      query: query,
    });
    if (response) {
      console.log(response.data, 'getConversation')
      setactive_conversation(response.data)
      setconversation_loader(false)
    } else {
      setactive_conversation({})
      setconversation_loader(false)
    }
  };
  const getContacts = async (agency_data) => {
    // alert(agency_data?.app_token?.company_id)
    const { response } = await Service.chat.getContacts({
      query: `company_id=${agency_data?.app_token?.company_id}&app_id=${agency_data?.app_token?.app_id}&agency_id=${agency_data._id}`,
    });
    if (!response) return setLoader(false);
    console.log(response.data, 'getContacts')
    setContacts(response.data)
    setLocations(response.locations)
    var user_chats = response.data.filter((contact) => response.chats.some(chat => chat.email === contact.email))
    user_chats = user_chats?.map((item) => ({
      ...item,
      type: 'user'
    }))
    console.log(user_chats, 'user_chatsuser_chats')
    var location_chats = response.locations.filter((location) => response.chats.some(chat => chat.location_id === location.id))
    location_chats = location_chats?.map((item) => ({
      ...item,
      type: 'location_chat'
    }))
    console.log(location_chats, 'location_chatslocation_chats')
    const custom_chats = response.chats.filter((item) => item.isCustom == true)
    const chats = {
      user_chats: user_chats,
      location_chats: location_chats,
      custom_chats: custom_chats,
    }
    setAllchats(chats)
    return setLoader(false);
  };

  const value =
    { contacts, active_conversation_user, setactive_conversation_user, getConversation, active_conversation, setactive_conversation, conversation_loader, allchats, getContacts, locations };



  const onLoad = () => {
    getContacts(agency);
  };

  useEffect(onLoad, [agency]);
  useEffect(() => {
    socket?.on("createlocationconversationresponse", async (data) => {
      getContacts(agency)
    });
    return () => {
      socket && socket.off("createlocationconversationresponse");
    };
  }, [socket]);

  return loader ? (
    <></>
  ) : (
    <contactContext.Provider value={value}>{children}</contactContext.Provider>
  );
}

// Material Dashboard 2 React custom hook for using context
function useContactsInfo() {
  return useContext(contactContext) || [];
}

// Typechecking props for the MaterialUIControllerProvider
ContactsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ContactsProvider, useContactsInfo };
