import React, { useEffect, useState } from "react";
import {
  Alert,
  Row,
  Col,
  Form,
  Label,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import localforage from "localforage";

// router
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";

// config
import config from "../../config";

// hooks
//actions

// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";
import { useAgencyInfo } from "../../context/agency";
import { useAppServices } from "../../hook/services";
import { useContactsInfo } from "../../context/contact";
import crypto from 'crypto-js';

interface LoginProps { }
const Login = (props: LoginProps) => {

  const navigate = useNavigate();
  const { company_id, app_id } = useParams()
  const [error, seterror] = useState<null | String>(null);
  const [redirectUrl, setRedirectUrl] = useState("/");
  const Service = useAppServices()
  const [agency, Update] = useAgencyInfo()
  const { getContacts } = useContactsInfo()

  const loginUser = async () => {
    // const Cred=JSON.parse(ciphertext)
    // const payload = {
    //   company_id: Cred.companyId,
    //   app_id: "65a7bf628c605678d064a335",
    // }
    // console.log(cred.companyId, 'ciphertextciphertextciphertext')
    const { response } = await Service.auth.userlogin({
      query: `company_id=${company_id}&app_id=${app_id}`,
    });
    console.log(response, 'loginUser')
    if (response) {
      // if (response.data.active_users != undefined && response.data.active_users?.length > 0) {
      //   var filteruser = response.data.active_users.filter(function (item: any) {
      //     return item.id === cred?.userId;
      //   });
      //   if (filteruser.length) {
      //     Update({ ...response.data, app_token: response.app_token })
      //     localforage.setItem("agency", { ...response.data, app_token: response.app_token });
      //     getContacts({ ...response.data, app_token: response.app_token })
      //     navigate(redirectUrl);
      //   } else {
      //     console.log('user not found')
      //     seterror('user not authenticated!')
      //   }
      // } else {

      Update({ ...response.data, app_token: response.app_token })
      localforage.setItem("agency", { ...response.data, app_token: response.app_token });
      getContacts({ ...response.data, app_token: response.app_token })
      navigate(`/dashboard/${company_id}/${app_id}`);
      // }
    } else {
      seterror('user not authenticated!')
    }
  }
  const decript_data = (payload: any, app: any) => {
    // console.log(payload, app, 'payloadpayloadpayloadpayload')
    const ciphertext = crypto.AES.decrypt(payload, app).toString(
      crypto.enc.Utf8
    );
    console.log(ciphertext, 'ciphertext')
    var creds = JSON.parse(ciphertext)
    console.log(creds, 'creds')

    // loginUser(creds)
  }
  const CheckSSO = () => {
    // decript_data('U2FsdGVkX19Zr40uxiaroV4PHt5rq83CDgupMhgAJQCEz1kCb0EtP8pJM4ih1khD08ZLuOLIigDta/gUZjoFZGZHTaXtqOiYffXQa/gvwDafJ7T2tRyV1H67lzUbmWEePoUvA1VbN4JvfgMi3RXBOlRwkw1/czSqYKVSkTJ+2pgHs30hEGLwxk51bN3jN42PJDu9/KXJGGDj3IBujzn5pQ==', "134ede44-3375-47f7-affb-9f5105a03f30");

    const key = new Promise((resolve) => {
      window.parent.postMessage({ message: "REQUEST_USER_DATA" }, "*");
      console.log(
        window.parent.postMessage({ message: "REQUEST_USER_DATA" }, "*"),
        "test data"
      );
      const temp = window.addEventListener("message", ({ data }) => {
        console.log(data, "window.addEventListener");
        if (data.message === "REQUEST_USER_DATA_RESPONSE") {
          console.log(data.payload, 'hamza test')
          decript_data(data.payload, "134ede44-3375-47f7-affb-9f5105a03f30");

        } else {
          seterror('user not authenticated!')
        }
        console.log(temp, 'temptemptemptemptemptemp')
      });
    });
  }
  useEffect(() => {
    // CheckSSO()
    loginUser()
  }, []);


  return (
    <div>
      <NonAuthLayoutWrapper>
        <Row className=" justify-content-center my-auto">
          <Col sm={8} lg={6} xl={5} className="col-xxl-4">
            <div className="py-md-5 py-4">
              <AuthHeader
                title="Signing In"
                subtitle="Please wait a moment to check the authentication..."
              />


              <Form
                // onSubmit={handleSubmit(onSubmitForm)}
                className="position-relative"
              >
                <div className="text-center mt-4">
                  {error != null ?
                    <Alert color="danger">{error}</Alert>
                    :
                    <Loader />
                  }
                  {/* <Button color="primary" className="w-100" type="submit">
                    Log In
                  </Button> */}
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </NonAuthLayoutWrapper>
    </div>
  );
};

export default Login;
