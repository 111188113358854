import React, { useState } from "react";
import classnames from "classnames";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//utils
import { DivideByKeyResultTypes } from "../../../utils";

// interfaaces
import { CompanyTypes } from "../../../data/contacts";
import AssignModal from "./AssignModal";

interface CompanyItemProps {
  company: CompanyTypes;
  onSelectChat: (company: object | number, isChannel?: boolean) => void;
}
const CompanyItem = ({ company, onSelectChat }: CompanyItemProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openModal, setopenModal] = useState(false);

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const fullName = `${company.name}`;
  const shortName = `${company?.name?.charAt(0)}`;
  const colors = [
    "bg-primary",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));

  return (
    <li onClick={() => onSelectChat({ ...company, type: 'location_chat' })}>
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0 me-2">
          <div className="avatar-xs">
            {company.profileImage ? (
              <img
                src={company.profileImage}
                alt=""
                className="img-fluid rounded-circle"
              />
            ) : (
              <span
                className={classnames(
                  "avatar-title",
                  "rounded-circle",
                  "font-size-10",
                  "text-uppercase",
                  colors[color]
                )}
              >
                {shortName}
              </span>
            )}
          </div>
        </div>
        <div className="flex-grow-1">
          <h5 className="font-size-14 m-0">{fullName}</h5>
        </div>
        <div className="flex-shrink-0">
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle tag="a" className="text-mute">
              <i className="bx bx-dots-vertical-rounded align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem
                onClick={() => setopenModal(true)}
                className="d-flex align-items-center justify-content-between"
              >
                Assign <i className="bx bx-user ms-2 text-muted"></i>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <AssignModal isOpen={openModal} onClose={() => setopenModal(false)} company={company} />
        </div>
      </div>
    </li>
  );
};
interface CharacterItemProps {
  letterCompanies: CompanyTypes;
  index: number;
  onSelectChat: (company: object | number, isChannel?: boolean) => void;
}
const CharacterItem = ({
  letterCompanies,
  index,
  onSelectChat,
}: CharacterItemProps) => {
  return (
    <div className="mt-3">
      <div className={classnames({ "mt-3": index !== 0 })}>
        {/* <div className="contact-list-title">{letterCompanies.letter}</div> */}

        <ul className="list-unstyled contact-list">
          {/* {(letterCompanies.data || []).map((contact: any, key: number) => ( */}
          <CompanyItem
            company={letterCompanies}
            // key={key}
            onSelectChat={onSelectChat}
          />
          {/* ))} */}
        </ul>
      </div>
    </div>
  );
};

export default CharacterItem;
