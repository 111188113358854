const config = {
  API_URL: "https://api.alltheapps.io",
  // API_URL: "http://localhost:8082",
  SERVER: "https://api.alltheapps.io/agency_app",
  // SERVER: "http://localhost:8082",
  AUTH_BACKEND: "Dummy",
  GHL: {
    CLIENT_ID: "65b8ce68bec5f88289483e52-lsj3objx",
    CLIENT_SECRET: "d4c84787-c1e1-4596-8dde-73cfd132b039",
    SCOPE:
      "contacts.readonly contacts.write campaigns.readonly conversations.readonly conversations.write forms.readonly conversations/message.write links.readonly links.write conversations/message.readonly locations.readonly locations/customFields.readonly locations/customFields.write locations/customValues.write locations/customValues.readonly locations/tags.write locations/tags.readonly locations/tasks.write locations/tasks.readonly opportunities.readonly opportunities.write locations/templates.readonly surveys.readonly users.write users.readonly workflows.readonly",
    REDIRECT: {
      LOCAITON: "https://chat.levelupmarketplace.io/integrations/ghl",
    },
  },
  FIRE_BASE: {
    API_KEY: process.env.REACT_APP_APIKEY,
    AUTH_DOMAIN: process.env.REACT_APP_AUTHDOMAIN,
    DATABASEURL: process.env.REACT_APP_DATABASEURL,
    PROJECTID: process.env.REACT_APP_PROJECTID,
    STORAGEBUCKET: process.env.REACT_APP_STORAGEBUCKET,
    MESSAGINGSENDERID: process.env.REACT_APP_MESSAGINGSENDERID,
    APPID: process.env.REACT_APP_APPID,
    MEASUREMENTID: process.env.REACT_APP_MEASUREMENTID,
  },
  GOOGLE: {
    API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    SECRET: process.env.REACT_APP_GOOGLE_SECRET_KEY,
  },
  FACEBOOK: {
    APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
  },
};

export default config;
