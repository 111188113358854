import { createContext, useContext, ReactNode, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import config from '../config';

// Define a socket interface
interface SocketContextProps {
  socket: Socket | null;
}

// Create a context with an initial value of null
const SocketContext = createContext<SocketContextProps>({ socket: null });

// Define a provider component that will wrap your application
interface SocketProviderProps {
  children: ReactNode;
}

const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  // Initialize your Socket.IO connection here
  const socket = io(config.SERVER);

  // Provide the socket value to the context
  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

// Custom hook to access the socket from any component
const useSocket = (): Socket | null => {
  const { socket } = useContext(SocketContext);

  if (!socket) {
    throw new Error('useSocket must be used within a SocketProvider');
  }

  return socket;
};

export { SocketProvider, useSocket };
