import React, { useState } from "react";
import { useAgencyInfo } from "../../../context/agency";
import { INTEGRATION_Data } from "./Constant";
import Select from 'react-select'
import { Button } from "reactstrap";
import { useAppServices } from "../../../hook/services";
interface PropsType {
  worflows: any[]
}
const Workflows = ({ worflows }: PropsType) => {
  const [agency] = useAgencyInfo()
  const Service = useAppServices()
  const workflowOptions = worflows?.map((item) => ({
    ...item,
    label: item.name,
    value: item.id,
  }))
  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const workflows = {
      message_received: e.target.message_received.value,
    }
    const payload = {
      workflows: workflows,
      _id: agency?._id
    }
    console.log(payload, 'payload')
    const { response } = await Service.agency.update({ payload })
    if (response) {
    }
  }
  return (
    <div className="accordion-body" >
      <form onSubmit={handleSubmit}>
        <div>

          <p className="text-muted mb-1" style={{ fontSize: '13px' }}>Message Received</p>
          <Select defaultValue={workflowOptions?.filter((item) => item.value == agency?.workflows?.message_received)[0]} options={workflowOptions} name="message_received" />
        </div>
        <div className="mt-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>

          <Button type="submit" color="success" >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Workflows;
