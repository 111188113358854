import React, { useEffect, useState } from "react";
import {
    Form,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Input,
} from "reactstrap";
import Select from 'react-select'
import { useAgencyInfo } from "../../../context/agency";
import { useAppServices } from "../../../hook/services";
import { ContactTypes } from "../../../data";

interface InviteContactModalProps {
    isOpen: boolean;
    onClose: () => void;
    contact: ContactTypes
}
const AssignModal = ({
    isOpen,
    onClose,
    contact
}: InviteContactModalProps) => {
    const [agency] = useAgencyInfo()
    const Service = useAppServices()
    const handleSubmit = async (e: any) => {
        e.preventDefault()
        const payload = {
            user: e.target.user.value,
            contactId: contact.id,
            agency_id: agency._id,
            company_id: agency?.app_token?.company_id,
            type: 'contact',
        }
        console.log(payload, 'payload')
        const { response } = await Service.assign_user.create({ payload })
        if (response) {
            onClose()
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable >
            <Form onSubmit={handleSubmit}>
                <ModalHeader toggle={onClose} className="bg-primary">
                    <div className="modal-title-custom text-white font-size-16 ">
                        Assign
                    </div>
                </ModalHeader>
                <ModalBody className="p-4">
                    <div className="mb-3" style={{ height: 350 }}>
                        <Label htmlFor="assign-users-input" className="form-label">
                            Users
                        </Label>
                        <Select name="user" options={agency?.active_users || []} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="link" className="btn" onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                    >
                        Submit
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default AssignModal;
