import React, { useEffect, useState } from "react";

// hooks
import { useProfile, useRedux } from "../../../hooks/index";
import { createSelector } from "reselect";
// components
import Loader from "../../../components/Loader";
import AppSimpleBar from "../../../components/AppSimpleBar";
import InviteContactModal from "../../../components/InviteContactModal";
import EmptyStateResult from "../../../components/EmptyStateResult";
import ListHeader from "./ListHeader";
import Contact from "./Contact";

// actions
import {
  getContacts,
  inviteContact,
  resetContacts,
  getChannelDetails,
  getChatUserDetails,
  getChatUserConversations,
  changeSelectedChat,
} from "../../../redux/actions";

//utils
import { divideByKey, DivideByKeyResultTypes } from "../../../utils";
import { useContactsInfo } from "../../../context/contact";
import { ContactTypes } from "../../../data";

interface IndexProps { }

const Index = (props: IndexProps) => {
  // global store
  // const { dispatch, useAppSelector } = useRedux();
  // const { userProfile } = useProfile();

  // const errorData = createSelector(
  //   (state: any) => state.Contacts,
  //   (state) => ({
  //     contactsList: state.contacts,
  //     getContactsLoading: state.getContactsLoading,
  //     isContactInvited: state.isContactInvited,
  //   })
  // );
  // Inside your component
  // const { contactsList, getContactsLoading, isContactInvited } = useAppSelector(errorData);


  // get contacts

  // useEffect(() => {
  //   dispatch(getContacts(`app_id=${userProfile?.app_token?.app_id}&company_id=${userProfile?.app_token?.company_id}`));
  // }, [dispatch]);
  const { contacts, setactive_conversation_user } = useContactsInfo()
  const [contactslist, setContactslist] = useState<Array<any>>(contacts);
  // console.log(contactslist, 'contactslistcontactslist')
  // const [contactsData, setContactsData] = useState<Array<any>>([]);
  // useEffect(() => {
  //   if (contactsList.length > 0) {
  //     // console.log(contactsList, 'contactsListcontactsList')
  //     setContacts(contactsList);
  //   }
  // }, [contactsList]);

  useEffect(() => {
    if (contacts.length > 0) {
      // const formattedContacts = divideByKey("firstName", contacts);
      setContactslist(contacts);
    }
  }, [contacts]);

  /*
  add contact modal handeling
  */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  /*
  onInvite handeling
  */
  const onInviteContact = (data: any) => {
    // dispatch(inviteContact(data));
  };
  // useEffect(() => {
  //   if (isContactInvited) {
  //     setIsOpen(false);
  //     setTimeout(() => {
  //       dispatch(resetContacts("isContactInvited", false));
  //     }, 1000);
  //   }
  // }, [dispatch, isContactInvited]);

  /*
  contact search
  */
  const [search, setSearch] = useState("");
  const onChangeSearch = (value: string) => {
    setSearch(value);
    let modifiedContacts = [...contacts];
    let filteredContacts = (modifiedContacts || []).filter((c: any) =>
      c["firstName"].toLowerCase().includes(value.toLowerCase())
    );
    setContactslist(filteredContacts);
  };

  const totalC = (contactslist || []).length;
  const onSelectChat = (contact: object | number, isChannel?: boolean) => {
    setactive_conversation_user(contact)
    // if (isChannel) {
    //   dispatch(getChannelDetails(id));
    // } else {
    //   dispatch(getChatUserDetails(id));
    // }
    // // dispatch(getChatUserConversations(id));
    // dispatch(changeSelectedChat(id));
  };

  return (
    <>
      <div className="position-relative">
        <ListHeader
          search={search}
          onChangeSearch={onChangeSearch}
          openModal={openModal}
        />

        <AppSimpleBar className="chat-message-list chat-group-list">
          <div>
            {totalC === 0 ? (
              <EmptyStateResult searchedText={search} />
            ) : (
              (contactslist || []).map(
                (letterContacts: ContactTypes, key: number) => (
                  <Contact
                    letterContacts={letterContacts}
                    key={key}
                    index={key}
                    onSelectChat={onSelectChat}
                  />
                )
              )
            )}
          </div>
        </AppSimpleBar>
      </div>
      <InviteContactModal
        isOpen={isOpen}
        onClose={closeModal}
        onInvite={onInviteContact}
      />
    </>
  );
};

export default Index;
