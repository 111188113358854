import React, { useState, useEffect } from "react";
import { Button, Collapse } from "reactstrap";
import classnames from "classnames";
import { createSelector } from "reselect";
// hooks
import { useRedux } from "../../../hooks/index";

// actions
import { getSettings, updateSettings } from "../../../redux/actions";

// constants

// interface
import { SettingsTypes } from "../../../data/settings";

// components
import Loader from "../../../components/Loader";
import env from '../../../config'
import AppSimpleBar from "../../../components/AppSimpleBar";
import Workflows from "./Workflows";
import { useAgencyInfo } from "../../../context/agency";
import { useAppServices } from "../../../hook/services";
import { INTEGRATION_COLLAPSES } from "./Constant";
import Data from "./data";
import CustomFields from "./CustomFields";
// import CopyToClipboard from 'react-copy-to-clipboard'
import ActiveUsers from "./ActiveUsers";
import { useContactsInfo } from "../../../context/contact";
import DefaultUsers from "./DefaultUsers";

interface CollapseItemTypes {
    value:
    | INTEGRATION_COLLAPSES.WORKFLOW
    | INTEGRATION_COLLAPSES.ACTIVEUSER
    | INTEGRATION_COLLAPSES.CUSTOM_FIELDS
    | INTEGRATION_COLLAPSES.DEFAULTUSER
    label: string;
    icon: string;
    component: any;
}

interface AccordianItemProps {
    item: CollapseItemTypes;
    onChange: (
        id:
            | null
            | INTEGRATION_COLLAPSES.WORKFLOW
            | INTEGRATION_COLLAPSES.ACTIVEUSER
            | INTEGRATION_COLLAPSES.CUSTOM_FIELDS
            | INTEGRATION_COLLAPSES.DEFAULTUSER

    ) => void;
    selectedMenu:
    | null
    | INTEGRATION_COLLAPSES.WORKFLOW
    | INTEGRATION_COLLAPSES.ACTIVEUSER
    | INTEGRATION_COLLAPSES.CUSTOM_FIELDS
    | INTEGRATION_COLLAPSES.DEFAULTUSER

}
const AccordianItem = ({
    item,
    selectedMenu,
    onChange,
}: AccordianItemProps) => {
    const isOpen: boolean =
        selectedMenu && selectedMenu === item.value ? true : false;
    const toggleCollapse = () => {
        if (isOpen) {
            onChange(null);
        } else {
            onChange(item.value);
        }
    };
    return (
        <div className="accordion-item" style={{ overflow: 'visible' }}>
            <div className="accordion-header" id="headerpersonalinfo">
                <Button
                    color="none"
                    className={classnames(
                        "accordion-button",
                        "font-size-14",
                        "fw-medium",
                        { collapsed: !isOpen }
                    )}
                    onClick={toggleCollapse}
                    type="button"
                >
                    <i className={classnames("text-muted", "me-3", item.icon)}></i>{" "}
                    {item.label}
                </Button>
            </div>
            <Collapse
                isOpen={isOpen}
                id="personalinfo"
                className="accordion-collapse"
            >
                {item.component}
            </Collapse>
        </div>
    );
};
interface IndexProps { }
const Index = (props: IndexProps) => {
    // global store
    const [agency] = useAgencyInfo()
    const { data, loader, handleRefresh } = Data()
    const { contacts } = useContactsInfo()
    const [copy, setCopy] = useState(false)
    const Service = useAppServices()
    const { dispatch, useAppSelector } = useRedux();


    const copyToClipboard = () => {
        const text = `https://chatapp.levelupmarketplace.io/{{user.email}}/${agency._id}/{{location.id}}`

        // Create a temporary textarea element to hold the text
        const textarea = document.createElement('textarea');
        textarea.value = text;
        textarea.setAttribute('readonly', '');
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';

        // Append the textarea to the body
        document.body.appendChild(textarea);

        // Select the text inside the textarea
        textarea.select();

        // Execute the copy command
        document.execCommand('copy');
        setCopy(true)
        // Remove the textarea from the DOM
        document.body.removeChild(textarea);

        setTimeout(() => {
            setCopy(false)
        }, 2000);
    };
    const errorData = createSelector(
        (state: any) => state.Settings,
        (state: any) => state.Profile,
        (state) => ({
            settingsData: state.settings,
            getSettingsLoading: state.getSettingsLoading,
            isSettingsFetched: state.isSettingsFetched,
        })
    );
    // Inside your component
    const { settingsData, getSettingsLoading } = useAppSelector(errorData);

    // get user settings
    useEffect(() => {
        dispatch(getSettings());
    }, [dispatch]);

    const [settings, setSettings] = useState<SettingsTypes>(settingsData);
    useEffect(() => {
        setSettings(settingsData);
    }, [settingsData]);

    /*
    api calling
    */
    const onChangeData = (field: string, value: any) => {
        dispatch(updateSettings(field, value));
    };

    /*
    collapse handeling
    */
    const [selectedMenu, setSelectedMenu] = useState<
        | null
        | INTEGRATION_COLLAPSES.WORKFLOW
        | INTEGRATION_COLLAPSES.ACTIVEUSER
        | INTEGRATION_COLLAPSES.CUSTOM_FIELDS
        | INTEGRATION_COLLAPSES.DEFAULTUSER

    >(null);

    const collapseItems: CollapseItemTypes[] = [
        {
            value: INTEGRATION_COLLAPSES.WORKFLOW,
            label: "WorkFlows",
            icon: "bx bxs-lock",
            component: (
                <Workflows worflows={data.worflows} />
            ),
        },
        // {
        //     value: INTEGRATION_COLLAPSES.CUSTOM_FIELDS,
        //     label: "Custom Fields",
        //     icon: "bx bxs-lock",
        //     component: (
        //         <CustomFields custom_fields={data.custom_fields} />
        //     ),
        // },
        {
            value: INTEGRATION_COLLAPSES.ACTIVEUSER,
            label: "Active User",
            icon: "bx bxs-user",
            component: (
                <ActiveUsers users={contacts} />
            ),
        },
        {
            value: INTEGRATION_COLLAPSES.DEFAULTUSER,
            label: "Default Users",
            icon: "bx bxs-user",
            component: (
                <DefaultUsers users={contacts} />
            ),
        },
    ];

    const onChangeCollapse = (
        id:
            null
            | INTEGRATION_COLLAPSES.WORKFLOW
            | INTEGRATION_COLLAPSES.ACTIVEUSER
            | INTEGRATION_COLLAPSES.CUSTOM_FIELDS
            | INTEGRATION_COLLAPSES.DEFAULTUSER

    ) => {
        setSelectedMenu(id);
    };
    const auth_url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${env.GHL.REDIRECT.LOCAITON}&client_id=${env.GHL.CLIENT_ID}&scope=${env.GHL.SCOPE}&state=${agency?._id}`
    const hanldeConnect = () => {
        var windowFeatures = 'width=600,height=600,menubar=no,location=no,toolbar=no,scrollbars=yes'

        // Open the URL in a new window with the specified features
        window.open(auth_url, '_blank', windowFeatures)
    }
    return (
        <div className="position-relative">
            {/* Start User profile description */}
            {loader ?
                <div className="" style={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Loader />
                </div>
                :
                <>
                    <div className="p-3  d-flex w-100" style={{ justifyContent: 'space-between' }}>

                        <h4 >Integration</h4>
                        {/* <CopyToClipboard
                            text={`https://chatapp.levelupmarketplace.io/{{user.email}}/${agency._id}/{{location.id}}`}
                            onCopy={(text: any, result: any) => {
                                if (result) {
                                    handleCopy();
                                }
                            }}
                        > */}
                        <Button type="button" id="copy-text-button" color="success" className="btn btn-sm" onClick={copyToClipboard}>
                            {copy ? "Copied" : "Copy Url"}
                        </Button>
                        {/* </CopyToClipboard> */}

                    </div>
                    <AppSimpleBar className="user-setting">
                        <div className="d-flex mx-3 mb-3">
                            <div
                                className={classnames(
                                    "accordion-button",
                                    "font-size-14",
                                    "fw-medium",
                                )}>
                                Location Connect
                            </div>
                            <Button type="button" color="success" className="btn btn-sm" onClick={hanldeConnect}>
                                Connect
                            </Button>
                        </div>
                        <div id="integration" className="accordion accordion-flush">
                            {(collapseItems || []).map((item: CollapseItemTypes, key: number) => (
                                <AccordianItem
                                    item={item}
                                    key={key}
                                    selectedMenu={selectedMenu}
                                    onChange={onChangeCollapse}
                                />
                            ))}
                        </div>
                        {/* end profile-setting-accordion */}
                    </AppSimpleBar>
                </>
            }
            {/* <Button type="submit" color="success" style={{ position: 'absolute', bottom: '-100px', right: "20px" }}>
                Save
            </Button> */}
        </div>
    );
};

export default Index;
