import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//  Redux States
import { ContactsActionTypes } from "./types";
import {
  contactsApiResponseSuccess,
  contactsApiResponseError,
} from "./actions";

// api
import {
  getContacts as getContactsApi,
  inviteContact as inviteContactApi,
} from "../../api/index";

// helpers
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";
const data = [{
  "id": "614ecab4c2a5e35f32f78659",
  "firstName": "Faulkner",
  "lastName": "Benjamin",
  "profileImage": "/static/media/avatar-5.123e30b003e261b19f83.jpg",
  "about": "If several languages coalesce, the grammar of the resulting.",
  "email": "adc@123.com",
  "location": "California, USA",
  "status": "Active",
  "isFavourite": true,
  "channels": [
    {
      "id": "61665bcb9a456823e282afa7",
      "name": "Landing Design"
    },
    {
      "id": "61665bcb9a41b4e8352ba610",
      "name": "Design Phase 2"
    }
  ],
  "media": {
    "total": 17,
    "list": [
      {
        "id": 1,
        "url": "/static/media/img-1.94735bdcb4171caaa01e.jpg"
      },
      {
        "id": 2,
        "url": "/static/media/img-2.7f759d38502a81e71815.jpg"
      },
      {
        "id": 3,
        "url": "/static/media/img-4.8111c4656c8bc3b62569.jpg"
      },
      {
        "id": 4,
        "url": "/static/media/img-1.94735bdcb4171caaa01e.jpg"
      }
    ]
  },
  "attachedFiles": {
    "total": 4,
    "list": [
      {
        "id": 1,
        "fileName": "design-phase-1-approved.pdf",
        "size": "12.5 MB",
        "downloadUrl": "#",
        "icon": "bx bx-file"
      },
      {
        "id": 2,
        "fileName": "Image-1.jpg",
        "size": "4.2 MB",
        "downloadUrl": "#",
        "icon": "bx bx-image"
      },
      {
        "id": 3,
        "fileName": "Image-2.jpg",
        "size": "3.1 MB",
        "downloadUrl": "#",
        "icon": "bx bx-image"
      },
      {
        "id": 4,
        "fileName": "Landing-A.zip",
        "size": "6.7 MB",
        "downloadUrl": "#",
        "icon": "bx bx-file"
      }
    ]
  }
}
]
function* getContacts({ payload: filters }: any) {
  try {
    const response: { data: any } = yield call(getContactsApi, filters);
    console.log(response)
    const data = response.data.data
    yield put(
      contactsApiResponseSuccess(ContactsActionTypes.GET_CONTACTS, data)
    );
  } catch (error: any) {
    yield put(
      contactsApiResponseError(ContactsActionTypes.GET_CONTACTS, error)
    );
  }
}

function* inviteContact({ payload: newPassword }: any) {
  try {
    const response: Promise<any> = yield call(inviteContactApi, newPassword);
    yield put(
      contactsApiResponseSuccess(ContactsActionTypes.INVITE_CONTACT, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      contactsApiResponseError(ContactsActionTypes.INVITE_CONTACT, error)
    );
  }
}

export function* watchGetContacts() {
  yield takeEvery(ContactsActionTypes.GET_CONTACTS, getContacts);
}

export function* watchInviteContact() {
  yield takeEvery(ContactsActionTypes.INVITE_CONTACT, inviteContact);
}

function* contactsSaga() {
  yield all([fork(watchGetContacts), fork(watchInviteContact)]);
}

export default contactsSaga;
