import React, { useState } from "react";
import { useAgencyInfo } from "../../../context/agency";
const SocialLinks = () => {
  const [agency] = useAgencyInfo()
  return (
    <div className="accordion-body">

      <div>
        <p className="text-muted mb-1" style={{ fontSize: '13px' }}>Facebook</p>
        <input type="text" className="form-control" defaultValue={agency?.profile?.socail_links?.facebook} name="facebook" />
      </div>
      <div className="mt-3">
        <p className="text-muted mb-1" style={{ fontSize: '13px' }}>Instagram</p>
        <input type="text" className="form-control" defaultValue={agency?.profile?.socail_links?.instagram} name="instagram" />
      </div>
      <div className="mt-3">
        <p className="text-muted mb-1" style={{ fontSize: '13px' }}>LinkedIn</p>
        <input type="text" className="form-control" defaultValue={agency?.profile?.socail_links?.linkedin} name="linkedin" />
      </div>
    </div>
  );
};

export default SocialLinks;
