// costants
import { TABS } from "../../constants/index";

export interface MenuItemType {
  id: number;
  key: string;
  icon: string;
  tooltipTitle: string;
  className?: string;
  tabId:
  | TABS.BOOKMARK
  | TABS.CALLS
  | TABS.CHAT
  | TABS.CONTACTS
  | TABS.SETTINGS
  | TABS.USERS
  | TABS.INTEGRATION
  | TABS.COMPANIES
  | TABS.PROFILE;
}
const MENU_ITEMS: MenuItemType[] = [
  // {
  //   id: 1,
  //   key: "pills-user-tab",
  //   icon: "bx bx-user-circle",
  //   tooltipTitle: "Profile",
  //   className: "d-none d-lg-block",
  //   tabId: TABS.USERS,
  // },
  {
    id: 2,
    key: "pills-chat-tab",
    icon: "bx bx-conversation",
    tooltipTitle: "Chats",
    tabId: TABS.CHAT,
  },
  {
    id: 3,
    key: "pills-contacts-tab",
    icon: "bx bxs-user-detail",
    tooltipTitle: "Contacts",
    tabId: TABS.CONTACTS,
  },
  // {
  //   id: 4,
  //   key: "pills-calls-tab",
  //   icon: "bx bx-phone-call",
  //   tooltipTitle: "Calls",
  //   tabId: TABS.CALLS,
  // },
  // {
  //   id: 5,
  //   key: "pills-bookmark-tab",
  //   icon: "bx bx-bookmarks",
  //   tooltipTitle: "Bookmark",
  //   tabId: TABS.BOOKMARK,
  // },
  {
    id: 5,
    key: "pills-companies-tab",
    icon: "bx bx-buildings",
    tooltipTitle: "Companies",
    tabId: TABS.COMPANIES,
  },
  {
    id: 6,
    key: "pills-profile-tab",
    icon: "bx bx-user",
    tooltipTitle: "Profile",
    tabId: TABS.PROFILE,
  },
  {
    id: 7,
    key: "pills-integration-tab",
    icon: "bx bx-cog",
    tooltipTitle: "Integration",
    tabId: TABS.INTEGRATION,
  },

];

export { MENU_ITEMS };
