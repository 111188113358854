const Roles = {
  AGENCY: "agency",
  ADMIN: "admin",
  SUPERADMIN: "superadmin",
  POS: "pos",
};

const Types = {
  AGENCY: "agency_user",
  ADMIN: "admin",
};

module.exports = {
  Roles,
  Types,
};
