import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useAgencyInfo } from "../context/agency";

const AuthProtected = (props: any) => {
  const { company_id, app_id } = useParams()
  const [agency] = useAgencyInfo()

  /*
    redirect is un-auth access protected routes via url
  */
  if (!agency?.app_token) {
    return (
      // <Navigate to={{ pathname: "/auth-login"}} />
      <Navigate to={{ pathname: `/auth-login/${company_id}/${app_id}` }} />
    );
  }

  return <>{props.children}</>;
};

export { AuthProtected };
