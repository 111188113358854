import React from "react";
import { Button, Col, Row } from "reactstrap";
import LEVELUPlogo from "../../../assets/images/LEVELUPlogo.png"
const Welcome = () => {
  return (
    <React.Fragment>
      <div className="chat-welcome-section">
        <Row className="w-100 justify-content-center">
          <Col xxl={5} md={7}>
            <div className="p-2 text-center">
              <div className="avatar-xl mx-auto mb-1">
                <img src={LEVELUPlogo} alt="logo" width={55} height={55} />
              </div>
              <h4>Welcome to Level Up Chat App</h4>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Welcome;
