import React, { useState } from "react";
// interface
import { BasicDetailsTypes } from "../../../data/settings";
import { useAgencyInfo } from "../../../context/agency";
interface PersonalInfoProps {
  basicDetails: BasicDetailsTypes;
}
const PersonalInfo = () => {
  const [agency] = useAgencyInfo()
  return (
    <div className="accordion-body">

      <div>
        <p className="text-muted mb-1" style={{ fontSize: '13px' }}>Comppany Name</p>
        <input type="text" className="form-control" defaultValue={agency?.profile?.personalinfo?.name} name="name" />
      </div>
      <div className="mt-3">
        <p className="text-muted mb-1" style={{ fontSize: '13px' }}>Email</p>
        <input type="text" className="form-control" defaultValue={agency?.profile?.personalinfo?.email} name="email" />
      </div>
      <div className="mt-3">
        <p className="text-muted mb-1" style={{ fontSize: '13px' }}>Website</p>
        <input type="text" className="form-control" defaultValue={agency?.profile?.personalinfo?.website} name="website" />
      </div>
      <div className="mt-3">
        <p className="text-muted mb-1" style={{ fontSize: '13px' }}>Phone</p>
        <input type="text" className="form-control" defaultValue={agency?.profile?.personalinfo?.phone} name="phone" />
      </div>
    </div>
  );
};

export default PersonalInfo;
